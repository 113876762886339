import React, { PropTypes } from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import styles from "../../apps/Profile/Profile.module.css";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import GroupBurger from "../../apps/ClassesGroups/CreateGroup/GroupBurger";
import Logout from "../Logout";
import { connect } from "react-redux";
import { HeaderContext } from "../../apps/Profile/contexts/HeaderProvider";
import QRModal from "../QrModal/qrmodal";
import CarrerModal from "../careerModal/CarrerModal";
import { changeCurrentLanguage, getPosts } from "../../redux/actions/dashboard";
import Referral from "../referral/referral";
import ShareModal from "../shareModal/ShareModal";
import {
  FETCH_SEARCH_RESULTS,
  GET_PROFILE_DATA_EDIT,
  GET_PROFILE_DATA_VIEW,
} from "../../redux/constants/actionTypes";
import {
  fetchProfileDataEdit,
  fetchSearchResultsData,
  fetchProfileDataView,
  deleteUser,
} from "../../redux/actions/profile";
import { fetchPeopleYouMayKnow } from "../../redux/actions/peopleyoumayknow";
import { userSubType } from "../../redux/constants/commonFunctions";
import { getUserSettings } from "../../redux/actions/zoomAction";
import i18next, { t } from "i18next";
import { withTranslation } from "react-i18next";
import LanguageButton from "../LanguageButton";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Recording from "../recording/Recording";
import { Avatar, Button, Divider, Typography } from "@mui/material";
import LocationModal from "../../apps/Dashboard/LocationModal/LocationModal";
import { map } from "jquery";
import ConfirmationModal from "../ConfirmationModal";
import { DiscoverContext } from "../../apps/Discover/DiscoverContext";

const searchTypeList = [
  { text: "Deals", icon: "/Image/DashImage/deals_icon.svg" },
  { text: "Stores", icon: "/Image/DashImage/stores_icon.svg" },
  { text: "Events", icon: "/Image/DashImage/deals2_icon.svg" },
  { text: "Profiles", icon: "/Image/header/profile_icon.svg" },
];

const userData = localStorage.getItem("userData");

class Header extends Component {
  constructor(props) {
    super();

    this.state = {
      showMenu: false,
      notifyChanges: false,
      search: "",
      logout: false,
      showModal: false,
      showQrModal: false,
      showCareerModal: false,
      showReferralModal: false,
      showShareModal: false,
      showDeal: true,
      showAudioModal: false,
      link: "ccy-beag-tdz",
      path: "",
      showLangMenu: false,
      showLocationModal: false,
      location_hover: false,
      location: JSON.parse(localStorage.getItem("address")),
      coordinates: {
        lat: JSON.parse(localStorage.getItem("lat")),
        lng: JSON.parse(localStorage.getItem("lng")),
      },
      // lang: ""
      lang:
        localStorage.getItem("i18nextLng") ||
        navigator.language ||
        navigator.userLanguage,
      anchorEl: null,
      searchType: "Deals",
      confirmDelete: false,
    };

    this.handleInputChange = (e) => {
      this.setState({ search: e.target.value });
    };
    this.signOut = () => {
      this.setState({ logout: true });
    };
  }

  QRModalCloser = () => {
    this.setState({ showQrModal: false });
  };

  QRModalOpener = () => {
    this.setState({ showQrModal: true });
  };

  CareerModalCloser = () => {
    this.setState({ showCareerModal: false });
  };

  CareerModalOpener = () => {
    this.setState({ showCareerModal: true });
  };

  ReferralModalOpener = () => {
    this.setState({ showReferralModal: true });
  };

  ReferralModalCloser = () => {
    this.setState({ showReferralModal: false });
  };

  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };

  confirmDeleteOpener = () => {
    this.setState({ confirmDelete: true });
  };

  confirmDeleteCloser = () => {
    this.setState({ confirmDelete: false });
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    });
  };

  navbar = (e) => {
    if (this.state.showMenu) {
      return (
        <div className={styles.dropdown_menu}>
          <ul>
            <li>
              <div className={styles.profile}>
                <img
                  src={
                    this.props.profilePicUrl
                      ? this.props.profilePicUrl
                      : this.props.avatarImg
                      ? this.props.avatarImg
                      : "/Image/header/ddefault_img.svg"
                  }
                  className={styles.drop_profile}
                  style={{ width: 32, height: 32, opacity: 1 }}
                />
                <h1
                  style={{
                    fontSize: 14,
                    color: "#000000",
                    textTransform: "capitalize",
                    textAlign: "left",
                  }}
                >
                  {" "}
                  {this.props.name ??
                    this.props?.first_name + " " + this.props?.last_name ??
                    "Vijaya Vajpayi"}{" "}
                  <br />{" "}
                  <span
                    style={{
                      fontSize: 12,
                      color: "#555555",
                      fontWeight: "normal",
                    }}
                  >
                    {this.props?.user?.user_subtype
                      ? userSubType(this.props.user?.user_subtype)
                      : "Other"}
                  </span>
                </h1>
              </div>
            </li>
            <hr className={styles.Hr2} />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "left",
                ml: 2,
                mt: 1,
              }}
            >
              {t("header.account")}
            </Typography>

            <div className={styles.menu}>
              <Link
                to={`/profile/${
                  JSON.parse(localStorage?.userData || "{}").profile_id
                }/edit`}
                style={{ textDecoration: "none" }}
              >
                <li
                  style={{
                    color: "#555555",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                >
                  {t("header.viewProfile")}
                </li>
              </Link>

              <div className={styles.menu}>
                <div onClick={this.confirmDeleteOpener}>
                  <li
                    style={{
                      color: "#555555",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                  >
                    {t("header.deleteAccount")}
                  </li>
                </div>
              </div>

              <hr className={styles.Hr2} />

              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textAlign: "left",
                  ml: 2,
                  mt: 1,
                }}
              >
                {t("header.manage")}
              </Typography>

              <Link
                to={`/postandactivity/${
                  JSON.parse(localStorage?.userData || "{}").profile_id
                }`}
                style={{ textDecoration: "none" }}
              >
                <li
                  style={{
                    color: "#555555",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                >
                  {t("header.myPosts&Activity")}
                </li>
              </Link>

              <Link to={"/myfavourites"} style={{ textDecoration: "none" }}>
                <li
                  style={{
                    color: "#555555",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                >
                  {t("header.myfav")}
                </li>
              </Link>

              <div className={styles.menu}>
                <div onClick={this.props.showReferral}>
                  <li
                    style={{
                      color: "#555555",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                  >
                    {t("header.referral")}
                  </li>
                </div>
              </div>

              <hr className={styles.Hr2} />
              <li
                onClick={this.signOut}
                style={{
                  color: "var(--primary-main)",
                  cursor: "pointer",
                  fontWeight: 500,
                }}
              >
                {t("header.signOut")}
              </li>
            </div>
          </ul>
        </div>
      );
    } else return <></>;
  };

  notification = () => {
    if (this.state.notifyChanges) {
      return (
        <div className={styles.notifications_menu}>
          <ul className="notifications_Dashboard_Dropdown">
            <li>
              <Link
                to={`/profile/${
                  JSON.parse(localStorage?.userData || "{}").profile_id
                }/edit`}
                style={{ textDecoration: "none" }}
              >
                <div className={styles.notification}>
                  <img
                    src="/Image/notification/userProfile.svg"
                    style={{ width: 30, height: 30, opacity: 1 }}
                  />
                  <p style={{ fontSize: 14, color: "#000000" }}>
                    {" "}
                    {t("dashboard.completeProfile")}
                    <br />
                  </p>
                </div>
              </Link>
            </li>
            <hr className={styles.Hr2} />
            <li>
              <Link to="/video" style={{ textDecoration: "none" }}>
                <div className={styles.notification}>
                  <img
                    src="/Image/notification/videoConfrence.svg"
                    style={{ width: 30, height: 30, opacity: 1 }}
                  />
                  <p style={{ fontSize: 14, color: "#000000", marginLeft: 5 }}>
                    {t("header.startLiveSession")} <br />
                  </p>
                </div>
              </Link>
            </li>
            <hr className={styles.Hr2} />
            <li>
              <div className={styles.notification}>
                <img
                  src="/Image/notification/searchMatch.svg"
                  style={{ width: 30, height: 30, opacity: 1 }}
                />
                <p
                  style={{
                    fontSize: 14,
                    color: "#000000",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (
                      this.props.history.location.pathname !== "/search/results"
                    ) {
                      this.props.history.push("/search/results");
                    }
                  }}
                >
                  {t("header.searchYourMatch")}
                  <br />
                </p>
              </div>
            </li>
          </ul>
        </div>
      );
    } else return <></>;
  };

  toogleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);

    const token = JSON.parse(localStorage.getItem("userData"))?.token || "";
    if (token != "") {
      this.props.fetchProfileDataEdit(
        GET_PROFILE_DATA_EDIT,
        token,
        JSON.parse(localStorage.userData).profile_id
      );
      this.props.getUserSettings();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        showMenu: false,
        notifyChanges: false,
      });
    }
  };

  idetifyChange = () => {
    this.setState({
      notifyChanges: !this.state.notifyChanges,
    });
  };

  openSidebar = () => {
    let { history } = this.props;
    history.push("/sidebar");
  };

  toggleLanguageMenu() {
    if (this.state.showLangMenu == true) {
      this.setState({ showLangMenu: false });
    } else {
      this.setState({ showLangMenu: true });
    }
  }

  handleCoordinatesChange = (coor) => {
    this.setState({ coordinates: coor });
  };

  handleAddress = (address) => {
    this.setState({ location: address });
    localStorage.setItem("lat", JSON.stringify(this.state.coordinates?.lat));
    localStorage.setItem("lng", JSON.stringify(this.state.coordinates?.lng));
    localStorage.setItem("address", JSON.stringify(address));

    console.log("add", address);
  };

  render() {
    let arr = window.location.href.split("/");
    let path = arr[3];
    const initLang =
      localStorage.getItem("i18nextLng") ||
      navigator.language ||
      navigator.userLanguage;

    const { t } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popper" : undefined;

    return (
      <>
        {this.state.logout ? <Logout /> : null}
        <HeaderContext.Consumer>
          {(value) => {
            if (value != null) {
              const [state, setEditPage, setSearchPage, setSearchText] = value;
              const onSearch = (e, from = "notAudio") => {
                if (from == "notAudio") {
                  const search = this.state.search;
                  setSearchText(search);
                } else {
                  const search = from;
                  this.setState({ search: from });
                  setSearchText(search);
                }
                e.preventDefault();

                if (
                  from == "notAudio" &&
                  from != "" &&
                  this.state.search.length == 0
                ) {
                  if (this.state.searchType === "Events") {
                    this.props.history.push(`/discover/event`);
                  } else if (this.state.searchType === "Deals") {
                    this.props.history.push("/discover/sale");
                  } else if (this.state.searchType === "Stores") {
                    this.props.history.push("/discover/business");
                  } else {
                    this.props.history.push("/connections");
                  }
                } else {
                  if (this.state.searchType === "Events") {
                    this.context.search(this.state.search, "events");
                    this.props.history.push(`/discover/event`);
                  } else if (this.state.searchType === "Deals") {
                    this.context.search(this.state.search, "deals");
                    this.props.history.push("/discover/sale");
                  } else if (this.state.searchType === "Stores") {
                    this.context.search(this.state.search, "stores");
                    this.props.history.push("/discover/business");
                  } else {
                    this.context.search(this.state.search, "profiles");
                    this.props.history.push("/connections");
                  }
                }
              };
              return (
                <div className={styles.header}>
                  <LocationModal
                    show={this.state.showLocationModal}
                    closeModal={() =>
                      this.setState({
                        showLocationModal: !this.state.showLocationModal,
                      })
                    }
                    method={this.handleAddress}
                    coordinates={this.state.coordinates}
                    setCoordinates={this.handleCoordinatesChange}
                  />
                  <ShareModal
                    closeModal={this.shareModalCloser}
                    show={this.state.showShareModal}
                    link={this.state.link}
                  />
                  <QRModal
                    closeModal={this.QRModalCloser}
                    show={this.state.showQrModal}
                  />
                  <CarrerModal
                    closeModal={this.CareerModalCloser}
                    show={this.state.showCareerModal}
                  />
                  <Referral
                    closeModal={this.ReferralModalCloser}
                    show={this.state.showReferralModal}
                  />
                  <ConfirmationModal
                    confirmDelete={this.state.confirmDelete}
                    confirmDeleteCloser={this.confirmDeleteCloser}
                    deleteUser={this.props.deleteUser}
                  />

                  <div className={styles.header_left}>
                    <Link to={userData ? "/user" : "/"}>
                      <img
                        className={styles.main_logo}
                        src="/Image/HomeImages/Logo.png"
                        alt="logo"
                        style={{
                          height: "auto",
                          width: "200px",
                        }}
                      />
                    </Link>

                    <div
                      className={styles.location_search}
                      style={{ position: "relative" }}
                      onMouseEnter={() =>
                        this.setState({ location_hover: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ location_hover: false })
                      }
                      onClick={() =>
                        this.setState({
                          showLocationModal: !this.state.showLocationModal,
                        })
                      }
                    >
                      <img
                        src="/Image/DashImage/location_icon.svg"
                        alt="location logo"
                        style={{ height: "22px" }}
                      />
                      <p
                        className={styles.location_search_text}
                        style={{ overflowY: "hidden", maxHeight: "20px" }}
                      >
                        {this.state.location
                          ? `${this.state.location?.slice(0, 15)}...`
                          : "India"}
                      </p>
                      <div
                        className={styles.location_hover}
                        style={{
                          display: this.state.location_hover ? "flex" : "none",
                        }}
                      >
                        {this.state.location}
                      </div>
                    </div>

                    <div className={styles.search_bar_box}>
                      <div
                        className={styles.search_bar_start}
                        onClick={() =>
                          this.setState({ showDeal: !this.state.showDeal })
                        }
                      >
                        <img
                          className={styles.down_deals_icon}
                          src={
                            this.state.searchType === "Deals"
                              ? "/Image/DashImage/deals_icon.svg"
                              : this.state.searchType === "Events"
                              ? "/Image/DashImage/deals2_icon.svg"
                              : this.state.searchType === "Profiles"
                              ? "/Image/header/profile_icon.svg"
                              : "/Image/DashImage/stores_icon.svg"
                          }
                          alt="deals icon"
                        />
                        <Typography sx={{ ml: 1 }}>
                          {this.state.searchType}
                        </Typography>
                        <img
                          className={styles.down_arrow_icon}
                          src="/Image/DashImage/searchbar-down-arrow.png"
                          alt="more icon"
                        />
                        <br />
                        <div
                          className={styles.deals}
                          style={{
                            display: this.state.showDeal ? "none" : "block ",
                          }}
                        >
                          {searchTypeList.map((type, idx) => (
                            <Button
                              key={idx}
                              disableRipple
                              startIcon={
                                <Avatar
                                  variant="square"
                                  alt="search types"
                                  src={type.icon}
                                  sx={{
                                    width: 18,
                                    height: "auto",
                                  }}
                                />
                              }
                              onClick={(e) =>
                                this.setState({ searchType: `${type.text}` })
                              }
                              sx={{
                                ":hover": { background: "#f5f5f5" },
                                width: "100%",
                                color: "#000",
                                textTransform: "none",
                                py: 0.2,
                              }}
                            >
                              {type.text}
                            </Button>
                          ))}
                        </div>
                      </div>

                      <img
                        className={styles.down_search_icon}
                        src="/Image/DashImage/search_1_icon.svg"
                        alt="search icon"
                        onClick={onSearch}
                      />
                      <form
                        className={styles.search_bar_mid}
                        onSubmit={(e) => onSearch(e)}
                      >
                        <input
                          className={styles.searchbar_input}
                          placeholder="Search deals, cashback, stores, events and more"
                          value={this.state.search}
                          onChange={this.handleInputChange}
                        />
                      </form>

                      <div className={styles.search_bar_end}>
                        <img
                          className={styles.mic_icon}
                          src="/Image/DashImage/mic_icon.svg"
                          alt="search icon"
                          onClick={() =>
                            this.setState({
                              showAudioModal: !this.state.showAudioModal,
                            })
                          }
                        />
                        <Recording
                          onSearch={onSearch}
                          showAudio={this.state.showAudioModal}
                          setShowAudio={this.setState}
                        />

                        <img
                          src="/Image/DashImage/qr_icon.svg"
                          className={styles.mic_icon}
                          onClick={this.props.show}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.header_right}>
                    <div className={styles.header_option}>
                      <Link to={`/user`} style={{ textDecoration: "none" }}>
                        <div className={styles.header_option_container}>
                          <img
                            src="/Image/header/home_icon.svg"
                            className={styles.image}
                            style={{
                              width: 30,
                              height: 30,
                            }}
                          />
                        </div>
                      </Link>
                    </div>

                    {/* <div className={styles.header_option}>
                      <div className={styles.header_option_container}>
                        <img
                          src="/Image/header/message_icon.svg"
                          className={styles.image}
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      </div>
                    </div> */}

                    <div className={styles.header_option}>
                      <LanguageButton />
                    </div>

                    <div className={styles.profile_icon}>
                      {window.location.pathname != "/video" ? (
                        window.location.pathname != "/settings" ? (
                          window.location.pathname != "/zoomconfiguration" ? (
                            window.location.pathname != "/subscription" ? (
                              window.location.pathname != "/connections" ? (
                                window.location.pathname !=
                                "/search/results" ? (
                                  window.location.pathname != "/classes" ? (
                                    window.location.pathname != "/groups" ? (
                                      !this.props?.hideMobileNav ? (
                                        <img
                                          src="/Image/nav_menu.jpeg"
                                          className={styles.header_nav}
                                          style={{ cursor: "pointer" }}
                                          onClick={() => this.openSidebar()}
                                        />
                                      ) : null
                                    ) : null
                                  ) : null
                                ) : null
                              ) : null
                            ) : null
                          ) : null
                        ) : null
                      ) : null}
                      <div className={styles.navbar_menu}>
                        <img
                          onClick={this.toogleMenu}
                          src={
                            this.props.profilePicUrl
                              ? this.props.profilePicUrl
                              : this.props.avatarImg
                              ? this.props.avatarImg
                              : "/Image/header/ddefault_img.svg"
                          }
                          className={styles.header_avatar}
                        />
                      </div>
                      <this.navbar setEditPage={setEditPage} state={state} />
                    </div>
                  </div>

                  {this.props.history.location.pathname ===
                  `/group/${this.props?.history?.location?.state?.subjectId}` ? (
                    <GroupBurger
                      routeType="Group"
                      subjectId={
                        this.props?.history?.location?.state?.subjectId
                      }
                    />
                  ) : this.props.history.location.pathname ===
                    `/class/${this.props?.history?.location?.state?.subjectId}` ? (
                    <GroupBurger
                      routeType="Class"
                      subjectId={
                        this.props?.history?.location?.state?.subjectId
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            } else {
              return null;
            }
          }}
        </HeaderContext.Consumer>
      </>
    );
  }
}

Header.contextType = DiscoverContext;

// export default withRouter(Header);
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user,
    first_name: state?.profile?.user?.first_name,
    last_name: state?.profile?.user?.last_name,
    profilePicUrl: state?.profile?.userProfilePic,
    avatarImg: state?.profile?.avatarImg,
    designation: state?.profile?.about?.designation,
    coverPicUrl: state?.profile?.userCoverPic,
    posts: state?.dashboard?.posts,
    name: state?.profile?.name,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    // getPosts: () => dispatch(getPosts()),
    fetchProfileDataEdit: (action, token, id) =>
      dispatch(fetchProfileDataEdit(action, token, id)),
    // fetchProfileDataView: (action, token, id) => dispatch(fetchProfileDataView(action, token, id)),
    fetchSearchResultsData: (action, url) =>
      dispatch(fetchSearchResultsData(action, url)),
    fetchPeopleYouMayKnow: (action, token) =>
      dispatch(fetchPeopleYouMayKnow(action, token)),
    getUserSettings: () => dispatch(getUserSettings()),
    deleteUser: () => dispatch(deleteUser()),
    // changeCurrentLanguage: (lang) => dispatch(changeCurrentLanguage(lang))
  };
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
);
